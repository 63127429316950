<template>
<div class="logo">
    <div class="logo_img">
      <!-- 广州 -->
      <img src="../../../assets/image/1.png" alt="">
      <!-- 城市合伙人 -->
      <!-- <img
        src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E7%99%BB%E5%BD%95%E9%A1%B5-liu.png"
        alt=""
      /> -->
      <!-- 佛山 -->
      <!-- <img
        src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/1-1.png"
        alt=""
      /> -->
      <!-- 动态获取是哪个地区的智参图片 -->
      <!-- <img :src="loginCover" alt="" /> -->
    </div>
    <div class="po_left">
      <div class="top_txt">
        <!-- <img
          src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/IMG-112470962共创会运营总后台.png"
          alt=""
        /> -->
        <img src="../../../assets/image/logo_top34.png" alt="">
        <!-- <img src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/IMG-965592419logo.png" alt="">
        <span>共创会管理后台</span> -->
      </div>
      <el-form
        ref="ruleForm"
        :model="loginInfo"
        :rules="rules"
        :hide-required-asterisk="true"
        :show-message="false"
        label-width="80px"
        class="form"
      >
       <div class="input_border">
        <div class="label_css">手机号</div>
        <el-form-item  prop="mobile">
          <el-input
            placeholder="请输入手机号"
            v-model="loginInfo.mobile"
            class="login-input"
          ></el-input>
        </el-form-item>
       </div>
      <div class="input_border">
        <div class="label_css">验证码</div>
        <el-form-item label="" prop="smsCode">
          <div class="bind_code">
            <el-input
              type="text"
              placeholder="请输入验证码"
              v-model="loginInfo.smsCode"
              class="login-input"
              @keyup.enter.native="onSubmit"
            >
            </el-input>
            <el-button
              type="primary"
              plain
              @click.native.prevent="bindforgetSendCode"
              class="codebtn"
              :disabled="disabled"
            >
              {{ btnText }}
            </el-button>
          </div>
        </el-form-item>
      </div>
        <el-form-item>
          <el-button v-if="!loading" class="buttom" type="success" @click="onSubmit"
            >登录</el-button
          >
          <el-button v-else icon="el-icon-loading" class="buttom" type="success"
            >登录中...</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login, getSms, listOperatorList } from '@/api/login'
export default {
  data() {
    return {
      loading:false,
      loginInfo: {
        mobile: '',
        smsCode: ''
      },
      pwdType: 'password',
      eyeType: 'icon-jr-icon-eye',
      btnText: '获取验证码',
      disabled: false,
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入手机号', trigger: 'blur' }
        ],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  created() {
    // this.searchOperatorList()
  },
  methods: {
    // 获取运营商列表
    async searchOperatorList() {
      const operatorUrl = window.location.origin // 返回基础 URL
      const res = await listOperatorList({ operatorUrl })
      if (res.resultCode === 200) {
        const operatorId = res.data.id // 获取运营商id
        sessionStorage.setItem('operatorId', operatorId)
      }
    },
    //发送验证码
    bindforgetSendCode() {
         
      this.$refs['ruleForm'].validateField('mobile', (errorMessage) => {
        
        if (!errorMessage) {
        
          getSms({ mobile: this.loginInfo.mobile }).then((res) => {
            
            if (res.resultCode === 200) {

              this.disabled = true
              this.btnText = '请稍候...'
              setTimeout(() => {
                this.doloop(60)
              }, 500)
              this.$message.success(res.message)
              // this.$message.success('验证码发送成功!')
            } else {
              this.$message.warning(res.message)
              // this.$message.error('验证码发送失败!')
            }
          })
        } else {
          this.$message.warning('请输入正确的手机号！')
           
          return false
        }
      })
    },
    //验证码倒计时
    doloop(seconds) {
      seconds = seconds ? seconds : 60
      this.btnText = seconds + 's后获取'
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + 's后获取'
          --seconds
        } else {
          this.btnText = '获取验证码'
          this.disabled = false
          clearInterval(countdown)
        }
      }, 1000)
    },

    //登录
    async onSubmit() {
      this.loading=true
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          login(this.loginInfo).then((response) => {
            if (response.resultCode === 200) {
              const token = response.data.token
                   // 定义一些写死的值
                   const typeMode = {
                id: 15, //合作伙伴编号
                directLeague: 2, //直营或加盟(1->直营;2->加盟)
                url: null, //机构画面链接
                cityPartner: null, //城市合伙人标识;1代表是城市合伙人
                cityPartnerId: null, //城市合伙人类型的合作伙伴关联的伙伴表主键，用,隔开"
                schemaName: '', //schema值
                manualLink: null, //操作链接
                zcName: null, //智参中心
                loginCover: null, //直营智参的登录页封面图
              }
              // 存入一些写死的值
              sessionStorage.setItem('typeMode', JSON.stringify(typeMode));
              sessionStorage.setItem('token', token)
              sessionStorage.setItem('roleId',  response.data.roleId)
              if(response.data.customerId){
                sessionStorage.setItem('customerId',  response.data.customerId)
              } 
              sessionStorage.setItem('unitId',  response.data.unitId||'')
              
               sessionStorage.setItem('userId', response.data.id)
              sessionStorage.setItem('userName', response.data.userName)
              sessionStorage.setItem('count', response.data.count)
              
              sessionStorage.setItem('actions', JSON.stringify(response.data.actions))
              // this.searchOperatorList()
              this.$message.success(response.message)
              this.$router.replace({
                path: '/'
              })
              this.loading=false
            } else {
              this.loading=false
              this.$message.warning(response.message)
            }
          })
        } else {
          this.$message.warning('请输入手机号跟验证码！')
          this.loading=false
        }
      })
    },
    changeType() {
      this.pwdType = this.pwdType === 'password' ? 'text' : 'password'
      this.eyeType = this.pwdType === 'password' ? 'icon-jr-icon-eye' : 'icon-jr-icon-close'
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #448aff;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 50px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
::v-deep .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  font-weight: 550;
  color: #b5b5b5;
  line-height: 50px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
::v-deep  .el-form-item__content {
    width: 480px;
    line-height: 40px;
    position: relative;
    font-size: 14px;
    margin-left: 5px !important;
}
::v-deep.el-form-item {
    margin-bottom: 1px;
}
.label_css{
  color: #868484;
  margin-left: 20px;
  font-size: 16px;
}
.logo {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  .bind_code {
    position: relative;
    .codebtn {
      position: absolute;
      right: 106px;
      font-size: 12px;
      height: 40px;
      line-height: 12px;
      top: 5px;
      width: 90px;
      border: 1px solid #ffffff;
      
      display: flex;
      justify-content: center;
    }
  }
  .logo_info {
    padding: 0 40px 0 50px;
    .logo_name {
      position: relative;
      top: -50px;
      img {
        width: 597px;
        height: 50px;
      }
    }
  }

  .logo_img {
    width: 100%;
    height: 100vh;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .po_left {
    position: absolute;
    top: 200px;
    right: 100px;
    width: 420px;
    height: 440px;
    padding:20px 40px 20px 40px;
    border-radius: 40px;
    background: #ffffff;
    border: 2px solid #e9e7e7;
    .top_txt {  
      width: 205px;
      margin-left: 120px;
      height: 70px;
      display: flex;
      align-items: center;
      color: #343595;
      font-size: 32px;
      font-weight: 550;
      padding: 20px 0px 30px 0px;
      img {
        width:100%;
      height: 100%;
       
      }
    }
  }
  .buttom {
    height: 60px;
    width: 400px;
    border: 1px solid #1673ff;
    background: #1673ff;
    border-radius: 82px;
    margin-top: 30px;
    font-size: 18px;
  }

  .form {
    .login-input {
      width: 380px;
    }
  }
}
.input_border{
  margin-bottom: 20px;
  border: 2px solid #e4e5e9;
  border-radius: 10px;
  padding: 10px 0px;
  width: 400px;
}
::v-deep  .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus {
    border-color: #fff;
}
</style>
